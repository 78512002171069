@import './bmw-colors.scss';

/* Notification and Error Colors */
$color-notification-ping: $color-typography-critical;
$color-error-message: $color-red-500;
$color-success: $color-typography-positive;

/* Panel Colors */
$color-panel-primary: $color-surface-base; /* White */
$color-panel-secondary: $color-surface-info; /* Blueish */
$color-panel-body: $color-surface-page; /* Greyish */
$color-panel-bg: $color-panel-primary;
$color-panel-header-bg: $color-cool-grey-300;
$color-panel-box-shadow: $color-typography-disabled;

/* Shadow Colors */
$color-card-shadow-start: var(--ds-color-surface-disabled, 0.2);
$color-card-shadow-end: var(--ds-color-surface-disabled, 0.1);
$color-control-shadow1: var(--ds-color-surface-disabled, 0.5);
$color-control-shadow2: var(--ds-color-grey-600, 0.5);

/* Font Colors */
$color-font-primary: $color-typography-base;
$color-font-secondary: $color-surface-base;
$color-font-label: $color-font-primary;
$color-font-content-header: $color-grey-200;
$color-font-content-message: $color-grey-500;
$color-font-blue-hint: $color-typography-info;

/* Icon Colors */
$color-icon-primary: $color-typography-base;
$color-icon-secondary: $color-surface-base;
$color-icon-disabled: $color-cool-grey-300;
$color-icon-background-primary: $color-grey-500;

/* Border Colors */
$color-border: $color-border-neutral;
$color-control-border: $color-typography-neutral;
$color-control-border-alternative: $color-border-neutral;
$color-control-border-footer: $color-cool-grey-500;

/* Control Colors */
$color-control-alternative: $color-surface-base;
$color-control-separator: $color-grey-100;
$color-control-separator-light: var(--Grey-grey-100, 0.5);
$color-control-hover-on: $color-grey-900;
$color-control-item-hover: $color-surface-neutral;
$color-control-item-hover-light: $color-grey-100;
$color-control-item-selected: $color-surface-neutral-mild;
$color-control-item-disabled: $color-surface-neutral;

/* Card Colors */
$color-card-bg: $color-surface-info;
$color-card-border: $color-border-action-strong;

/* Footer Map Colors */
$color-footer-map-bg: $color-typography-base;
$color-footer-map-border: $color-typography-base;

/* Dropdown Colors */
$color-dropdown-bg: $color-panel-primary;

/* Table Colors */
$color-table-column-border: $color-surface-base;
$color-table-head: $color-font-secondary;
$color-table-head-bg: $color-typography-neutral;
$color-table-separator: $color-control-separator;
$color-table-message-bg: $color-grey-100;

/* Battery Colors */
$color-battery-high: $color-green-300;
$color-battery-medium: $color-yellow-200;
$color-battery-low: $color-red-400;
$color-battery-border: $color-cool-grey-700;

/* Status Label Colors */
$color-status-label-default-bg: $color-grey-500;
$color-status-label-active-bg: $color-typography-positive;
$color-status-label-draft-bg: $color-typography-neutral;
$color-status-label-archive-bg: $color-yellow-300;

/* Additional Colors */
$color-add-button: $color-blue-500;
$color-box-shadow: var(--ds-color-typography-disabled, 0.16);
$color-label-bg: $color-typography-neutral;

/* User Settings Colors */
$color-user-settings-title-background: $color-grey-200;
$color-user-settings-list-options: var(--ds-color-typography-disabled, 0.11);
$color-user-settings-options: $color-typography-neutral;

/* Dolly Colors */
$dolly-loaded: $color-blue-500;
$dolly-free: $color-cool-grey-300;

/* Error Message Colors */
$color-error-message-bg: $color-surface-critical-hover;
$color-error-message-text: $color-typography-critical;

/* Loading Bar Colors */
$color-ng-loading-bar-shade1: $color-green-200;
$color-ng-loading-bar-shade2: $color-blue-300;

/* Loader Border Colors */
$color-loader-border-shade1: $color-ocean-600;
$color-loader-border-shade2: $color-ocean-100;

/* Background Hover */
$color-bg-hover: $color-grey-100;

/* Slider Colors */
$color-slider-bg: $color-grey-500;
$color-slider-range-bg: $color-grey-700;

/* Icon Card Colors */
$color-icon-card-button: $color-blue-300;
$color-icon-card: $color-blue-400;

/* Line and Separator Colors */
$color-line-separator: $color-grey-300;
$color-notification-bg: $color-surface-neutral;
$color-panel-border-top: $color-grey-400;
$color-line-segment-separator: $color-border-neutral;

/* Table Border Colors */
$color-table-border-bottom: $color-grey-200;
$color-zone-table-border-bottom: $color-grey-200;
$color-searchbar-box-shadow: $color-cool-grey-500;
$color-footer-map-separator-border-right: $color-surface-base;

/* Separator Borders */
$color-separator-border: $color-cool-grey-400;
$color-index-table-border-bottom: $color-grey-100;

/* Card Icon Backgrounds */
$color-card-icon-background: $color-typography-neutral;
$color-card-icon-background2: $color-grey-300;
$color-card-icon-text: $color-typography-neutral;

/* Label Background Colors */
$color-label-background1: $color-surface-info;
$color-label-background2: var(--Green-green-300, 0.47);
$color-label-background3: $color-yellow-200;
$color-label-background4: $color-grey-400;

/* Error Status Colors */
$color-fatal-error-status: $color-typography-critical;
$color-warning-error-status: $color-gamboge-400;
$color-info-error-status: $color-typography-positive;
$color-selected-card-border: $color-border-action-strong-hover;

/* Selected Card Box Shadow */
$color-selected-card-box-shadow1: var(--ds-color-typography-disabled, 0.2);
$color-selected-card-box-shadow2: var(--ds-color-typography-disabled, 0.12);
