@import './variables.scss';

ag-grid-angular {
  flex: 1;
  height: 100%;
  min-height: 100%;
}

.ag-header-cell-text {
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

:host ::ng-deep {
  .ag-theme-density.zebra {
    .row-highlight-warning {
      background-color: $color-red-100 !important;

      &:hover {
        background-color: $color-red-200 !important;
      }
    }
  }

  .row-highlight-info {
    background-color: $color-grey-100 !important;

    &:hover {
      background-color: $color-grey-200 !important;
    }
  }
}
