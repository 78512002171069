@import 'src/scss/variables.scss';

div#stats {
  position: fixed;
  top: 85px;
  left: 50px;
  z-index: 500;
  width: max(200px, 10vw, 10vh);
  height: max(100px, 6vh, 6vw);
  opacity: 0.8;
  user-select: none;
}

.landingPageContent {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: $color-panel-primary;
}

.landingPageContent > .outer-spacer {
  height: 60px;
}

.landingPageContent > div {
  display: flex;
  justify-content: center;
}

.landingPageContent > .mid-spacer {
  flex-grow: 1;
}

.landingPageContent > .inner-spacer {
  height: 50px;
}

.logo {
  height: 200px;
  width: auto;
}

.logo img {
  height: 200px;
  width: auto;
}

.loading-spinner {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: $color-loader-border-shade1 $color-loader-border-shade2 $color-loader-border-shade2;
  border-image: initial;
  animation: rotate-fully 1.3s cubic-bezier(0.53, 0.21, 0.29, 0.67) infinite;
}

.landingPageContent > .loader-container {
  animation: delayed-appearance 0.2s 0.4s linear 1 forwards;
  opacity: 0;
}

.resultsTable {
  font-size: $font-size-small;
  width: 100%;
  border-spacing: 0;

  .header-row {
    .date {
      font-weight: bold;
      padding-right: 32px;
      padding-left: 0;
    }

    .total {
      font-weight: bold;
      padding-right: 54px;
    }
  }

  .showMoreLess {
    text-align: end;
    padding: 24px 8px 8px 0;
    margin-bottom: -24px;
    a {
      cursor: pointer;
    }
    .space {
      margin-right: 8px;
    }
  }

  .tableRows {
    &:last-child td {
      border-bottom: 0;
    }

    td {
      border-bottom: 1px solid $color-index-table-border-bottom;
      padding: 6px 0;

      &:last-child {
        font-weight: bold;
        padding-right: 48px;
      }
    }
  }

  &.legend-total {
    margin: 0 auto;
    font-weight: bold;
    text-align: center;
  }
}

.totalResult {
  font-size: $font-size-small;
  font-weight: bold;
  text-align: center;
}

.slideInDown {
  animation-name: slideInDown;
  animation-duration: 0.5s;
}

.warning {
  color: $color-typography-critical;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -3%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotate-fully {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes delayed-appearance {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 90%;
  }
  100% {
    opacity: 1;
  }
}
