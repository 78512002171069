@import './variables/fonts.scss';
@import './variables/colors.scss';
@import './variables/bmw-colors.scss';
@import './ag-grid.scss';
@import '../../node_modules/@bmw-ds/components/density-styles/styles/scss/density-styles-ng.scss';
@import '../../node_modules/@bmw-ds/components/density-styles/styles/scss/ag-grid-theme/_ag-grid-theme-v28+.scss';

@mixin font-style(
  $bold: false,
  $trunc: false,
  $upperCase: false,
  $size: $font-size,
  $color: $color-font-label,
  $font: var(--ds-typography-family-base)
) {
  font-family: $font;
  color: $color !important;
  font-size: $size;

  @if $bold==true {
    font-weight: bold;
  }

  @if $trunc==true {
    @include text-truncate();
  }

  @if $upperCase==true {
    text-transform: uppercase;
  }
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin row-link($includePadding: false, $paddingValues...) {
  display: inline;

  @if $includePadding == true {
    @include padding($paddingValues);
  }
}

@mixin padding($values) {
  @each $var in $values {
    padding: #{$var};
  }
}

.regular-text {
  @include font-style(false);
}

.regular-text-small {
  @include font-style(false, false, false, $font-size-small);
}

.regular-bold {
  @include font-style(true);
}

.small-bold {
  @include font-style(true, false, false, $font-size-small);
}

.regular-ellipse {
  @include font-style(false, true);
}

.regular-information {
  color: $color-font-secondary;
}

.medium-text {
  @include font-style(false, false, false, $font-size-medium);
}

.small-content-message {
  @include font-style(false, false, false, $font-size-extra-small, $color-font-content-message);
}

.content-message-header {
  @include font-style(false, false, false, $font-size-large-heading, $color-font-content-header);
  margin-bottom: 16px;
}

.content-message {
  @include font-style(false, false, false, $font-size-large, $color-font-content-message);
}

.normal-row-link {
  @include row-link(true, (0px 4px 8px 31px));
}

.secondary-label {
  @include font-style(true, false, true, $font-size, $color-font-secondary);
}

.button-label {
  @include font-style(true, false, false, $font-size-large, BMW Group);
}

// REMOVE
.actionMenuHeader {
  width: 90px;
  min-width: 90px;
  max-width: 90px;
}

.actionMenuCell {
  width: 90px;
  min-width: 90px;
  max-width: 90px;

  .center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  i {
    font-size: xx-large;
  }
}

.full-width-button {
  width: 100%;
  display: inline-block;
}

.navigation-list-item.ds-list-item {
  font-size: 14px;
  align-items: center;
  height: 40px;
  padding-left: var(--ds-space-6x);
  display: flex;
}

// overwriting ctw-theme; may be removed later.
h1 {
  font-family: var(--ds-typography-family-brand);
  font-size: 36px;
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: 16px;
}

h2 {
  //title lg
  font-family: var(--ds-typography-family-brand);
  font-size: 26px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 16px;
}

h3 {
  //title md
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 16px;
  text-transform: unset;
}

h4 {
  //title sm
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 16px;
}

h5 {
  //title xs
  font-size: var(--ds-typography-size-regular);
  line-height: 16px;
  font-weight: var(--ds-typography-weight-strong);
  margin-top: 0;
  margin-bottom: 8px;
  text-transform: unset;
}

small,
span.small-text,
.label-text,
.invalid-input-text,
.dialog-title,
.dialog-content,
.modalMessageText1,
.modalMessageText2,
.p-inputtext,
.p-link,
.p-component {
  font-family: var(--ds-typography-family-base);
}

.p-datatable .p-datatable-thead > tr > th,
.p-treetable .p-treetable-thead > tr > th,
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: var(--ds-typography-weight-regular);
}

.ds-button {
  text-transform: capitalize;
}

.ds-label {
  size: var(--ds-typography-size-label);
  color: var(--ds-color-typography-neutral);
}

.ds-tabs {
  height: 100%;
}

.ds-tabs .ds-box-content {
  height: 100%;
  overflow-y: scroll;
}

.ds-tabs-label .ds-box-header__label-wrapper {
  text-transform: capitalize !important;
}

.ds-list-item__content {
  font-size: var(--ds-typography-size-04) !important;
  overflow: unset !important;
  text-transform: capitalize;
}

.cdk-overlay-container {
  z-index: 1001;
}

.labeled-text {
  color: $color-text-input-label;
  line-height: var(--ds-font-line-height-input);
}

.mission-step-icon {
  margin-right: 5px;
}

.form-plaintext {
  size: var(--ds-typography-size-regular);
  color: var(--ds-color-typography-base);
}

.position-description {
  size: var(--ds-typography-size-regular);
  color: var(--ds-color-typography-base);
}

.last-changed-date {
  color: var(--ds-color-typography-neutral);
}

.box-content-header {
  size: var(--ds-typography-size-title-md);
  color: var(--ds-color-typography-base);
}
