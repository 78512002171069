/*
 * BMW Group IT UI Density Colour Guidelines
 * https://density-dev.bmwgroup.net/v8/foundations/color/color-palette
 * https://density-dev.bmwgroup.net/v8/foundations/color/color-tokens
 *
 * Last Update: 21-11-2024
 */

/* Basic Colors */
$color-surface-base: var(--ds-color-surface-base);
$color-surface-neutral: var(--ds-color-surface-neutral);
$color-surface-neutral-mild: var(--ds-color-surface-neutral-mild);
$color-surface-neutral-medium: var(--ds-color-surface-neutral-medium);
$color-typography-base: var(--ds-color-typography-base);
$color-typography-neutral: var(--ds-color-typography-neutral);

/* Highlight and Accent Colors */
$color-surface-action-mild: var(--ds-color-surface-action-mild);
$color-surface-caution: var(--ds-color-surface-caution);
$color-surface-caution-hover: var(--ds-color-surface-caution-hover);
$color-surface-caution-strong: var(--ds-color-surface-caution-strong);
$color-typography-caution: var(--ds-color-typography-caution);

/* More Colors */
$color-border-positive: var(--ds-color-border-positive);
$color-surface-action-strong: var(--ds-color-surface-action-strong);
$color-surface-badge-caution: var(--ds-color-surface-badge-caution);
$color-surface-badge-critical: var(--ds-color-surface-badge-critical);
$color-surface-critical-hover: var(--ds-color-surface-critical-hover);
$color-surface-critical-strong: var(--ds-color-surface-critical-strong);
$color-surface-disabled: var(--ds-color-surface-disabled);
$color-surface-info: var(--ds-color-surface-info);
$color-surface-input-error: var(--ds-color-surface-input-error);
$color-surface-invert: var(--ds-color-surface-invert);
$color-surface-neutral-strong: var(--ds-color-surface-neutral-strong);
$color-surface-page: var(--ds-color-surface-page);
$color-typography-action: var(--ds-color-typography-action);
$color-typography-action-active: var(--ds-color-typography-action-active);
$color-typography-critical: var(--ds-color-typography-critical);
$color-typography-disabled: var(--ds-color-typography-disabled);
$color-typography-info: var(--ds-color-typography-info);
$color-typography-positive: var(--ds-color-typography-positive);
$row-selected-background-color: var(--row-selected-background-color);

/* Text & Border Colors */
$color-border-action-strong: var(--ds-color-border-action-strong);
$color-border-action-strong-hover: var(--ds-color-border-action-strong-hover);
$color-border-neutral: var(--ds-color-border-neutral);
$color-text-input-label: var(--ds-color-text-input-label);
$color-text-tag: var(--ds-color-text-tag);

/* Plain & Cool Colors */
$color-avocado-400: var(--ds-color-avocado-400);
$color-blue-100: var(--ds-color-blue-100);
$color-blue-200: var(--ds-color-blue-200);
$color-blue-300: var(--ds-color-blue-300);
$color-blue-400: var(--ds-color-blue-400);
$color-blue-500: var(--ds-color-blue-500);
$color-blue-600: var(--ds-color-blue-600);
$color-blue-700: var(--ds-color-blue-700);
$color-blue-800: var(--ds-color-blue-800);
$color-cool-grey-200: var(--ds-color-cool-grey-200);
$color-cool-grey-300: var(--ds-color-cool-grey-300);
$color-cool-grey-400: var(--ds-color-cool-grey-400);
$color-cool-grey-500: var(--ds-color-cool-grey-500);
$color-cool-grey-700: var(--ds-color-cool-grey-700);
$color-cool-grey-950: var(--ds-color-cool-grey-950);
$color-gamboge-400: var(--ds-color-gamboge-400);
$color-green-100: var(--ds-color-green-100);
$color-green-200: var(--ds-color-green-200);
$color-green-300: var(--ds-color-green-300);
$color-green-400: var(--ds-color-green-400);
$color-green-500: var(--ds-color-green-500);
$color-green-600: var(--ds-color-green-600);
$color-green-700: var(--ds-color-green-700);
$color-green-800: var(--ds-color-green-800);
$color-green-900: var(--ds-color-green-900);
$color-grey-100: var(--ds-color-grey-100);
$color-grey-200: var(--ds-color-grey-200);
$color-grey-300: var(--ds-color-grey-300);
$color-grey-400: var(--ds-color-grey-400);
$color-grey-500: var(--ds-color-grey-500);
$color-grey-600: var(--ds-color-grey-600);
$color-grey-700: var(--ds-color-grey-700);
$color-grey-900: var(--ds-color-grey-900);
$color-ocean-100: var(--ds-color-ocean-100);
$color-ocean-200: var(--ds-color-ocean-200);
$color-ocean-300: var(--ds-color-ocean-300);
$color-ocean-400: var(--ds-color-ocean-400);
$color-ocean-500: var(--ds-color-ocean-500);
$color-ocean-600: var(--ds-color-ocean-600);
$color-ocean-700: var(--ds-color-ocean-700);
$color-ocean-800: var(--ds-color-ocean-800);
$color-red-100: var(--ds-color-red-100);
$color-red-200: var(--ds-color-red-200);
$color-red-400: var(--ds-color-red-400);
$color-red-500: var(--ds-color-red-500);
$color-white: var(--ds-color-white);
$color-yellow-200: var(--ds-color-yellow-200);
$color-yellow-300: var(--ds-color-yellow-300);
