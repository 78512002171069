@import './variables.scss';

$skeleton-base-color: $color-grey-100;
$skeleton-animation-color: $color-surface-neutral;

.skeleton {
  animation: loading 1.7s infinite ease-out;
  background: $skeleton-base-color;
  background-image: linear-gradient(
    to right,
    $skeleton-base-color 0%,
    $skeleton-animation-color 20%,
    $skeleton-base-color 40%,
    $skeleton-base-color 100%
  );
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 80%;
}

@keyframes loading {
  0% {
    background-position: -100px;
  }

  100% {
    background-position: 200px;
  }
}
